<template>
   <EditorItem :item="vItem"/>
</template>
<script setup>    
    import EditorItem from 'o365.vue.components.InputEditors.Base.Item.vue';
    import { ref,defineEmits, watch, onBeforeUnmount } from 'vue';
    import InputEditorsItem  from 'o365.modules.inputEditorsItem.ts';
    const emit = defineEmits(['change']);
    const props = defineProps({
        modelValue: null,
        item:Object,
        for:String,
        addFormClasses:{
            type:Boolean,
            default:false
        },
        valueSetter: Function,
        valueGetter: Function
    });

    const vItem = ref(null);
  
    const watcherCancelFns = [];
    
    function updateItem2() {
        if (props.item.PropertyName != null) {
            vItem.value = new InputEditorsItem(props);
        } else {
            vItem.value = {};
        }
    }
    
    watcherCancelFns.push(watch(() => props.item, () => { updateItem2(); }));
    watcherCancelFns.push(watch(() => props.item?.PropertyName, () => { updateItem2(); }));

    updateItem2();    

    onBeforeUnmount(() => {
        watcherCancelFns.forEach(unwatch => {
            unwatch();
        });
    });
</script>
